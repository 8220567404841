import { dbCollections } from "@/utils/FirebaseCollections"
import * as mongoSnap from "@/utils/MongoQueries/crudOperationsWithSanpshot/crudOperationsWithSanpshot"
import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations"
import { BSON } from "realm-web";

export const getTasksFromMongoDB = ({ state, commit }, payload) => {
    return new Promise((resolve, reject) => {
        try {
            const {pid, sprintId, userId, showAllTasks,isSupport,customerId,groupBy} = payload;

            const projectFound = Object.keys(state.tasks).includes(pid);
            let sprintFound = false;
            if(projectFound) {
                sprintFound = state.tasks[pid].sprints.includes(sprintId);
            }

            Object.values(state.tasks || {}).forEach((data) => {
                if(data.projectId !== pid) {
                    let sprints = data.sprints;

                    sprints.forEach((sprint) => {
                        if(data[sprint].snapshot !== null) {
                            data[sprint].snapshot.return();
                        }
                    })
                    commit("removeProjectTaskSnap", data.projectId)
                }
            })

            if(projectFound && groupBy?.type !== state.tasks?.[pid]?.groupBy?.type) {
                state.tasks[pid].groupBy = groupBy;
            }

            if(sprintFound && state.tasks[pid][sprintId].snapshot && state.tasks[pid][sprintId].snapshot !== null) {
                resolve();
                return;
            }

            if(state.tasks[pid] === undefined || state.tasks[pid][sprintId] === undefined) {
                return;
            }

            const queryParams = {
                filter: {
                    $or: [
                        {
                            "operationType": {$in: ["delete"]},
                        },
                        {
                            "operationType": {$in: ["insert", "update", "replace"]},
                            "fullDocument.ProjectID": BSON.ObjectID(pid),
                            "fullDocument.sprintId": BSON.ObjectID(sprintId),
                            ...((showAllTasks === undefined || showAllTasks === true || showAllTasks === 2) ? {} : {"fullDocument.AssigneeUserId": userId}),
                            ...( isSupport ? { [`fullDocument.customField.${process.env.VUE_APP_CUSTOMFIELDID}.fieldValue`]: customerId } : {} )
                        }
                    ]
                }
            }
            const query = {
                subCollection: dbCollections.TASKS,
                watchFilter: queryParams
            }

            mongoSnap.mongodbSnapshot(query, ({error, snap, data, type}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        commit('mutateUpdateFirebaseTasks', {snap, op: "inital", pid, sprintId, data: null, groupBy})
                        // commit('mutateMongoUpdatedTask', {snap, op: "inital", pid, sprintId, data: null})
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit('mutateUpdateFirebaseTasks', {snap, op: "added", pid, sprintId, data: {...docData}})
                        // commit('mutateMongoUpdatedTask', {snap, op: "added", pid, sprintId, data: {...docData}})
                    } else if(type === "update") {
                        const docData = data.fullDocument;
                        commit('mutateUpdateFirebaseTasks', {snap, op: "modified", pid, sprintId, data: {...docData},updatedFields:{...data?.updateDescription.updatedFields},showAllTasks})
                        commit('mutateMongoUpdatedTask', {snap, op: "modified", pid, sprintId, data: {...docData}})
                    } else if(type === "replace") {
                        const docData = data.fullDocument;
                        commit('mutateUpdateFirebaseTasks', {snap, op: "modified", pid, sprintId, data: {...docData}})
                        commit('mutateMongoUpdatedTask', {snap, op: "modified", pid, sprintId, data: {...docData}})
                    } else if(type === "delete") {
                        const docData = data.documentKey;
                        commit('mutateUpdateFirebaseTasks', {snap, op: "removed", pid, sprintId, data: {...docData}})
                        commit('mutateMongoUpdatedTask', {snap, op: "removed", pid, sprintId, data: {...docData}})
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    })
}

export const getPaginatedTasks = ({state, commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            const {pid, sprintId, item, fetchNew, parentId = "", indexName = "", showAllTasks,isSupport,customerId} = payload;

            const indName = indexName || item.indexName

            const projectFound = Object.keys(state.tasks).includes(pid);
            let sprintFound = false;
            if(projectFound) {
                sprintFound = state.tasks[pid].sprints.includes(sprintId);
            }

            if(sprintFound && !fetchNew) {
                resolve();
                return;
            }

            let cursor = null;
            let foundKey = `${item.searchKey}_${item.searchValue}`;

            const indexKey = `${parentId && parentId.length ? `${parentId}_` : ''}${item.searchKey}_${item.searchValue}`;

            if(sprintFound) {
                cursor = state.tasks[pid][sprintId].index[indexKey] || null;
            }

            const queryParams = [
                {
                    $match: {
                        ProjectID: BSON.ObjectID(pid),
                        sprintId: BSON.ObjectID(sprintId),
                        deletedStatusKey: 0,
                        ...((showAllTasks === undefined || showAllTasks === true || showAllTasks === 2) ? {} : {AssigneeUserId: {$in: [payload.userId]}}),
                        ...( parentId && parentId.length ? { ParentTaskId: parentId } : {isParentTask: true, ...( item.conditions?.length ? { ...item.conditions[0] } : "" )} ),
                        ...( isSupport ? { [`customField.${process.env.VUE_APP_CUSTOMFIELDID}.fieldValue`]: customerId } : {} ),
                        ...( parentId && parentId.length ? 
                            { ParentTaskId: parentId }
                        :
                            {
                                isParentTask: true,
                                ...( item.mongoConditions?.length ? 
                                    { ...item.mongoConditions[0] }
                                :
                                    item?.conditions?.length ?
                                        { ...item.conditions[0] }
                                    :
                                        {}
                                )
                            }
                        ),
                    }
                },
                { $sort: {[indName]: 1, "createdAt": 1, _id: 1}},
            ]

            const query = {
                type: "aggregate",
                collection: dbCollections.TASKS,
                data: [
                    [
                        ...queryParams,
                        {
                            $facet: {
                                result:[
                                    { $skip: cursor || 0},
                                    { $limit: 35}
                                ],
                                count:[
                                    {$count: "count" }
                                ]
                            }
                        }
                    ]
                ]
            }

            mongoQuery.mongodbCrudOperations(query)
            .then((response) => response?.[0])
            .then((response) => {
                const responseData = response.result;

                let resCount = {}
                if(sprintFound && state.tasks[pid][sprintId].found?.[foundKey]) {
                    resCount = {[foundKey]:state.tasks?.[pid]?.[sprintId]?.found?.[foundKey] || 0};
                } else {
                    resCount = {[foundKey]:response.count?.[0]?.count || 0};
                }

                // SET CURSOR
                if(responseData && responseData.length) {
                    responseData.forEach(async (task) => {
                        const doc = task;

                        if(doc.favouriteTasks && doc.favouriteTasks.length && typeof doc.favouriteTasks[0] === "string") {
                            doc.favouriteTasks = doc.favouriteTasks.map((x) => ({...x}))
                        }
                        if(doc.startDate && doc.startDate > 0) {
                            doc.startDate = new Date(doc.startDate * 1000);
                        }

                        if(doc.DueDate && doc.DueDate > 0) {
                            doc.DueDate = new Date(doc.DueDate * 1000);
                            // doc.dueDateDeadLine = doc.dueDateDeadLine.map((x) => JSON.parse(x)).map((x) => ({date: new Date(x.date * 1000)}));
                        }

                        commit('mutateTypesenseTaskss', {found: resCount, nextPage: {[indexKey]: (cursor || 0) + responseData?.length || 0}, pid: pid, sprintId: sprintId, data: {...doc}})
                    })
                } else {
                    commit('mutateTypesenseTaskss', {found: resCount, nextPage: {[indexKey]: (cursor || 0) + responseData?.length || 0}, pid: pid, sprintId: sprintId, data: null})
                }

                resolve({responseData});
            })
            .catch((error) => {
                reject(error);
            })

        } catch (error) {
            reject(error);
        }
    })
}